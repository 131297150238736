import {
  HomeOutlined,
  UserOutlined,
  ShopOutlined,
  FileDoneOutlined,
  GroupOutlined,
  LaptopOutlined,
  QuestionCircleOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "home",
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [],
    access: "ALL",
  },
  {
    key: "company",
    path: `${APP_PREFIX_PATH}/company`,
    title: "sidenav.apps.company",
    icon: ShopOutlined,
    breadcrumb: true,
    submenu: [],
    access: "MASTER",
  },
  {
    key: "personal-company",
    path: `${APP_PREFIX_PATH}/personal-company`,
    title: "sidenav.apps.company",
    icon: ShopOutlined,
    breadcrumb: true,
    submenu: [],
    access: "ADMIN",
  },
  {
    key: "jobs",
    path: `${APP_PREFIX_PATH}/jobs`,
    title: "sidenav.apps.jobs",
    icon: FileDoneOutlined,
    breadcrumb: true,
    submenu: [],
    access: "ALL",
  },
  {
    key: "faq",
    path: `${APP_PREFIX_PATH}/faq`,
    title: "sidenav.apps.faq",
    icon: QuestionCircleOutlined,
    breadcrumb: true,
    submenu: [],
    access: "MASTER",
  },
  {
    key: "videos",
    path: `${APP_PREFIX_PATH}/videos`,
    title: "sidenav.apps.videos",
    icon: VideoCameraOutlined,
    breadcrumb: true,
    submenu: [],
    access: "MASTER",
  },
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users`,
    title: "sidenav.pages.userlist",
    icon: UserOutlined,
    breadcrumb: true,
    submenu: [],
    access: "ALL",
  },
  {
    key: "config",
    path: `${APP_PREFIX_PATH}/home`,
    title: "sidenav.apps.config",
    icon: GroupOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "work-type",
        path: `${APP_PREFIX_PATH}/work-type`,
        title: "sidenav.apps.work_type",
        icon: GroupOutlined,
        breadcrumb: true,
        submenu: [],
        access: "MASTER",
      },
      {
        key: "job-area",
        path: `${APP_PREFIX_PATH}/job-area`,
        title: "sidenav.apps.job_area",
        icon: LaptopOutlined,
        breadcrumb: true,
        submenu: [],
        access: "MASTER",
      },
    ],
    access: "MASTER",
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
