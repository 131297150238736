import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { Menu, Dropdown, Avatar } from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  LockOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/app/profile",
  },
  {
    title: "Change Password",
    icon: LockOutlined,
    path: "/app/profile/add/changepass",
  },

  /*{
		title: "Account Setting",
		icon: SettingOutlined,
		path: "/"
    },
    {
		title: "Billing",
		icon: ShopOutlined ,
		path: "/"
	},
    {
		title: "Help Center",
		icon: QuestionCircleOutlined,
		path: "/"
	}*/
];

export const NavProfile = ({ loggedUser, signOut }) => {
  let history = useHistory();
  const gotToChangePass = () => {
    history.push({
      pathname: `${APP_PREFIX_PATH}/profile/add/changepass`,
      state: { page: "changepass" },
    });
  };

  const profileImg = "/img/logo-sm.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{loggedUser.name}</h4>
            {loggedUser.company && loggedUser.company.name && (
              <span className="text-muted">{loggedUser.company.name}</span>
            )}
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={(e) => signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loggedUser } = auth;
  return { loggedUser };
};

export default connect(mapStateToProps, { signOut })(NavProfile);
