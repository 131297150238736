import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Badge, Avatar, List, Button } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { APP_PREFIX_PATH } from "../../configs/AppConfig";
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import notificationData from "assets/data/notification.data.json";
import NotificationsService from "services/Notifications";
import Flex from "components/shared-components/Flex";
import { createFormattedDateTimePartsComponent } from "react-intl/src/components/createFormattedComponent";

const getIcon = (icon) => {
  switch (icon) {
    case "mail":
      return <MailOutlined />;
    case "alert":
      return <WarningOutlined />;
    case "check":
      return <CheckCircleOutlined />;
    default:
      return <MailOutlined />;
  }
};

export const NavNotification = (props) => {
  const { loggedUser } = props;
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  let history = useHistory();

  useEffect(() => {
    loadNotifications();
  }, []);

  const loadNotifications = async () => {
    if (loggedUser.role === "MASTER") {
      const response = await NotificationsService.listByType({
        listTypes: [{ label: "new_company" }, { label: "new_job" }],
      });
      setData(response);
    } else if (loggedUser.role === "ADMIN") {
      const response = await NotificationsService.listByType({
        listTypes: [{ label: "update_company_status" }],
      });

      if (loggedUser.company) {
        let notificationsByCompany = [];
        response.map((item) => {
          if (item.company.id === loggedUser.company.id) {
            notificationsByCompany.push(item);
          }
          setData(notificationsByCompany);
        });
      }
    }
  };

  const getLiteralDate = (data) => {
    return moment(data).format("MMMM Do YYYY, h:mm");
  };

  const updateNotification = async (data) => {
    data.visualized = true;
    let result = await NotificationsService.update(data);
    console.log(result);
  };

  const clearAllData = async () => {
    data.map((item) => {
      updateNotification(item);
    });
    setData([]);
  };

  const openLink = (selected) => {
    updateNotification(selected);

    let urlLink = "";
    switch (selected.type) {
      case "new_job":
        urlLink = "/app/jobs/edit/" + selected.job.id;
        break;
      case "new_company":
        urlLink = "/app/company/edit/" + selected.company.id;
        break;
    }
    if (urlLink !== "") history.push(urlLink);
    removeNotification(selected);
  };

  const removeNotification = (selected) => {
    let list = [];

    data.map((item) => {
      if (item.id !== selected.id) {
        list.push(item);
      }
    });

    setData(list);
  };

  const getNotificationBody = (list) => {
    return list.length > 0 ? (
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(item) => (
          <List.Item
            className="list-clickable"
            onClick={() => {
              openLink(item);
            }}
          >
            <div>
              <div className="mr-3">
                <span className="text-gray-light">{item.description}</span>
              </div>
              <div className="mr-3">
                <small className="ml-auto">
                  {getLiteralDate(item.created)}
                </small>
              </div>
            </div>
          </List.Item>
        )}
      />
    ) : (
      <div className="empty-notification">
        <img
          src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
          alt="empty"
        />
        <p className="mt-3">You have viewed all notifications</p>
      </div>
    );
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button
          className="text-primary"
          type="text"
          onClick={() => clearAllData()}
          size="small"
        >
          Clear{" "}
        </Button>
      </div>
      <div className="nav-notification-body">{getNotificationBody(data)}</div>
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={data.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loggedUser } = auth;
  return { loggedUser };
};

export default connect(mapStateToProps)(NavNotification);
