import React , { useEffect } from "react";
import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { useHistory } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import { checkToken } from 'redux/actions/Auth';
import Logo from '../components/layout-components/Logo';

const RouteInterceptor = ({ children, isAuthenticated, props, ...rest }) => {

  const { loggedUser } = props;
  
  return (
    <Route
      {...rest}
      render={({ location }) => 
      {
        if( loggedUser !== null ){
          if(isAuthenticated)
          {
            return children;
          } else {
            return (<Redirect
              to={{
                pathname: AUTH_PREFIX_PATH,
                state: { from: location }
              }}
            />);
          }
        } else {
          return "Carregando...";
        }
      }}
    />
  );
}

export const Views = (props) => {
  const { locale, token, loggedUser, signUpSuccess, checkToken, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  let history = useHistory();
  useBodyClass(`dir-${direction}`);

  useEffect(()=>
  {
    if( signUpSuccess ){
      history.push(`${AUTH_PREFIX_PATH}/login`);
    }
  },[signUpSuccess]);

  useEffect(()=>
  {
    checkToken(token);
  },[]);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token} props={props}>
            <AppLayout direction={direction} location={location}/>
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}


const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } =  theme;
  const { token , signUpSuccess , loggedUser } = auth;
  return { locale, direction, token , loggedUser, signUpSuccess }
};

const mapDispatchToProps = {
	checkToken
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Views));