import { env } from "../configs/EnvironmentConfig";
const BASE_API = env.API_ENDPOINT_URL;

const NotificationsService = {};

NotificationsService.listByType = async (data) => {
  const req = await fetch(`${BASE_API}/notifications/listByType`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const json = await req.json();
  return json;
};

NotificationsService.update = async (data) => {
  const req = await fetch(`${BASE_API}/notifications/${data.id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const json = await req.json();
  return json;
};

export default NotificationsService;
