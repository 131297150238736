import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/profile`}
          component={lazy(() => import(`./profile`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./users`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/company`}
          component={lazy(() => import(`./company`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/personal-company`}
          component={lazy(() => import(`./personal-company`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/jobs`}
          component={lazy(() => import(`./jobs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/work-type`}
          component={lazy(() => import(`./work-type`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/job-area`}
          component={lazy(() => import(`./job-area`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/faq`}
          component={lazy(() => import(`./faq`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/videos`}
          component={lazy(() => import(`./videos`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
