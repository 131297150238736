const dev = {
  //API_ENDPOINT_URL:"https://tv81emzy02.execute-api.us-east-1.amazonaws.com/dev",
  API_ENDPOINT_URL:"https://2jilk62lxl.execute-api.us-east-1.amazonaws.com/production",
  //API_ENDPOINT_URL: "http://localhost:3001",
};

const prod = {
  API_ENDPOINT_URL:
    "https://2jilk62lxl.execute-api.us-east-1.amazonaws.com/production",
};

const test = {
  API_ENDPOINT_URL:
    "https://tv81emzy02.execute-api.us-east-1.amazonaws.com/dev",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
