import { env } from "../configs/EnvironmentConfig";
const BASE_API = env.API_ENDPOINT_URL;
const AuthService = {};

AuthService.signInEmailRequest = async (email, password) => {
  const req = await fetch(`${BASE_API}/users/auth/admin/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password, admin: true }),
  });
  const json = await req.json();
  return json;
};

AuthService.signUpEmailRequest = async (name, email, phone, password) => {
  const req = await fetch(`${BASE_API}/users/insert`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, email, phone, password, role: "ADMIN" }),
  });

  const json = await req.json();
  return json;
};

AuthService.checkToken = async (token) => {
  const req = await fetch(`${BASE_API}/token/refresh`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },

    body: JSON.stringify({ oldToken: token }),
  });

  const json = await req.json(token);
  return json;
};

AuthService.resetPass = async (data) => {
  const req = await fetch(`${BASE_API}/users/auth/admin/resetPassword`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const json = await req.json();
  return json;
};

AuthService.changePass = async (data) => {
  const req = await fetch(`${BASE_API}/users/auth/admin/changePassword`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const json = await req.json();
  return json;
};

export default AuthService;
