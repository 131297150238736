import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	UPDATE_LOGGED_USER,
	CHECK_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	setUpdateLoggedUser,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated
} from "../actions/Auth";

import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';

export function* callUpdateLoggedUser() {
	yield takeEvery(UPDATE_LOGGED_USER, function* (user) {
		  try {
			yield put(setUpdateLoggedUser(user));
		  } catch (err) {
			  yield put(showAuthMessage(err , 'error'));
		  }
	  });
  }

export function* signInWithFBEmail() {
  
  yield takeEvery(SIGNIN, function* ({payload}) {
		const {email, password} = payload;
		try {
			const response = yield call(AuthService.signInEmailRequest, email, password);
			if( response.status && response.data )
			{
				localStorage.setItem(AUTH_TOKEN, response.access_token);
				yield put(authenticated(response.access_token,response.data));
			} else {
				yield put(showAuthMessage(response.message , 'error' ));
			}
		} catch (err) {
			yield put(showAuthMessage(err , 'error'));
		}
	});
}


export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message , 'error'));
			}
		} catch (err) {
			yield put(showAuthMessage(err , 'error'));
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		const {name, email, phone, password} = payload;
		try {
			const response = yield call(AuthService.signUpEmailRequest, name, email, phone, password);
			console.log(response);

			if( response.status )
			{
				//localStorage.setItem(AUTH_TOKEN, response.access_token);
				yield put(showAuthMessage(response.message, 'success'));
				yield put(signUpSuccess(true));;
			} else {
				yield put(showAuthMessage(response.message , 'error' ));
			}
		} catch (error) {
			yield put(showAuthMessage(error , 'error'));
		}
	}
	);
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message , 'error'));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error , 'error'));
		}
	});
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message , 'error'));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error , 'error'));
		}
	});
}

export function* signInWithToken() {
  
	yield takeEvery(CHECK_TOKEN, function* (payload) {
		  const {token} = payload;
		  if( token !== null ){
			try {
				const response = yield call(AuthService.checkToken, token);
				if( response.status )
				{
					if( response.data ){
						localStorage.setItem(AUTH_TOKEN, response.access_token);
						yield put(authenticated(response.access_token,response.data));
					} else {
						yield put(authenticated(null,{}));
					}
				} else {
					yield put(showAuthMessage(response.message , 'error' ));
				}
			} catch (err) {
				yield put(showAuthMessage(err , 'error'));
			}
		  } else {
			yield put(authenticated(null,{}));
		  }
	  });
  }

export default function* rootSaga() {
  yield all([
		fork(callUpdateLoggedUser),
		fork(signInWithFBEmail),
		fork(signInWithToken),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook)
  ]);
}
