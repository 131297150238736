import {
	LOAD_LIST,
} from '../constants/WorkType';

const initState = {
  list:[]
}

const worktype = (state = initState, action) => {
	switch (action.type) {
		case LOAD_LIST:
			return {
				...state,
				list: action.list
			}
		default:
			return state;
	}
}

export default worktype